<template>
  <section>
    <v-row>
      <!-- E-mail -->
      <v-col cols="12" class="mb-6">
        <h2>E-mail</h2>
        <p>
          Para a realização da troca do e-mail será necessário solicitar o
          código de segurança que será enviado no e-mail atual.
        </p>
        <v-btn
          @click="requestCode('E')"
          :loading="loadings.loadingE"
          class="mb-6"
          color="primary"
          small
          outlined
        >
          Solicitar código
        </v-btn>
        <v-row>
          <!-- Código -->
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-text-field
              label="Código de segurança"
              placeholder="XXXXX"
              outlined
              dense
              v-model="trocaEmail.codigo"
              data-vv-as="código"
              persistent-hint
              :hint="errors.first('res-candidato-sensiveis-email.codigo')"
              :error="
                errors.collect('res-candidato-sensiveis-email.codigo').length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect('res-candidato-sensiveis-email.codigo').length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change|blur|input"
              data-vv-scope="res-candidato-sensiveis-email"
              ref="codigo"
              id="codigo"
              name="codigo"
            ></v-text-field>
          </v-col>
          <!-- E-mail -->
          <v-col cols="12" sm="12" md="8" lg="9">
            <v-text-field
              label="Novo E-mail"
              placeholder="xxxxxxx@email.com"
              outlined
              dense
              v-model="trocaEmail.email"
              data-vv-as="e-mail"
              persistent-hint
              :hint="errors.first('res-candidato-sensiveis-email.email')"
              :error="
                errors.collect('res-candidato-sensiveis-email.email').length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect('res-candidato-sensiveis-email.email').length
                  ? false
                  : true
              "
              v-validate="'required|email'"
              data-vv-validate-on="change|blur|input"
              data-vv-scope="res-candidato-sensiveis-email"
              ref="email"
              id="email"
              name="email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="saveEmail()"
              :loading="loadings.loadingSaveEmail"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- Senha -->
      <v-col cols="12">
        <h2>Senha</h2>
        <p>
          A troca de senha segui o mesmo processo que a do e-mail, será
          necessário solicitar o código de segurança. A seguir o código será
          enviado no seu e-mail.
        </p>
        <v-btn
          @click="requestCode('S')"
          :loading="loadings.loadingS"
          class="mb-6"
          color="primary"
          small
          outlined
        >
          Solicitar código
        </v-btn>
        <v-row justify="center">
          <!-- Código -->
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-text-field
              label="Código de segurança"
              placeholder="XXXXX"
              outlined
              dense
              v-model="trocaSenha.codigo"
              data-vv-as="código"
              persistent-hint
              :hint="errors.first('res-candidato-sensiveis-senha.codigo')"
              :error="
                errors.collect('res-candidato-sensiveis-senha.codigo').length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect('res-candidato-sensiveis-senha.codigo').length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change|blur|input"
              data-vv-scope="res-candidato-sensiveis-senha"
              ref="codigo"
              id="codigo"
              name="codigo"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="9"
            class="d-none d-sm-flex"
          ></v-col>
          <!-- Senha -->
          <v-col cols="12" sm="6">
            <v-text-field
              label="Nova Senha"
              placeholder="xxxxxxxxxxxxxxx"
              outlined
              dense
              v-model="trocaSenha.password"
              data-vv-as="senha"
              persistent-hint
              :hint="errors.first('res-candidato-sensiveis-senha.senha')"
              :error="
                errors.collect('res-candidato-sensiveis-senha.senha').length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect('res-candidato-sensiveis-senha.senha').length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change|blur|input"
              data-vv-scope="res-candidato-sensiveis-senha"
              ref="senha"
              id="senha"
              name="senha"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <!-- Confirma Senha -->
          <v-col cols="12" sm="6">
            <v-text-field
              label="Confirma Senha"
              placeholder="xxxxxxxxxxxxxxx"
              outlined
              dense
              v-model="trocaSenha.password_confirmation"
              data-vv-as="confirma senha"
              persistent-hint
              :hint="
                errors.first('res-candidato-sensiveis-senha.confirmaSenha')
              "
              :error="
                errors.collect('res-candidato-sensiveis-senha.confirmaSenha')
                  .length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect('res-candidato-sensiveis-senha.confirmaSenha')
                  .length
                  ? false
                  : true
              "
              v-validate="'required|confirmed:senha'"
              data-vv-validate-on="change|blur|input"
              data-vv-scope="res-candidato-sensiveis-senha"
              ref="confirmaSenha"
              id="confirmaSenha"
              name="confirmaSenha"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="savePassword()"
              :loading="loadings.loadingSaveSenha"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "InformacoesSensiveis",
  inject: ["$validator"],
  data() {
    return {
      trocaEmail: {
        codigo: null,
        email: null,
      },
      trocaSenha: {
        codigo: null,
        password: null,
        password_confirmation: null,
      },

      showPassword: false,

      loadings: {
        loadingE: false,
        loadingS: false,
        loadingSaveEmail: false,
        loadingSaveSenha: false,
      },
    };
  },
  methods: {
    saveEmail() {
      this.$validator
        .validate("res-candidato-sensiveis-email.*")
        .then((result) => {
          if (result) {
            this.loadings.loadingSaveEmail = true;

            this.$store
              .dispatch("usuarios/changeEmailCandidato", this.trocaEmail)
              .then((response) => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "green",
                  mensagem: "E-mail alterado com sucesso.",
                  duracao: 3000,
                });

                localStorage.clear();

                this.$store.dispatch("resCandidatos/resetModal");
                this.$router.push({ name: "auth-login" });
              })
              .catch(() => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "red",
                  mensagem:
                    "Ocorreu um erro ao alterar o e-mail. Por favor, tente novamente.",
                  duracao: 3000,
                });
              })
              .finally(() => {
                this.trocaEmail = {
                  codigo: null,
                  email: null,
                };
                this.loadings.loadingSaveEmail = false;
              });
          }
        });
    },
    savePassword() {
      this.$validator
        .validate("res-candidato-sensiveis-senha.*")
        .then((result) => {
          if (result) {
            this.loadings.loadingSaveSenha = true;

            this.$store
              .dispatch("usuarios/changeSenhaCandidato", this.trocaSenha)
              .then((response) => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "green",
                  mensagem: "Senha alterada com sucesso.",
                  duracao: 3000,
                });

                localStorage.clear();

                this.$store.dispatch("resCandidatos/resetModal");
                this.$router.push({ name: "auth-login" });
              })
              .catch(() => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "red",
                  mensagem:
                    "Ocorreu um erro ao alterar a senha. Por favor, tente novamente.",
                  duracao: 3000,
                });
              })
              .finally(() => {
                this.trocaSenha = {
                  codigo: null,
                  password: null,
                  password_confirmation: null,
                };
                this.loadings.loadingSaveSenha = false;
              });
          }
        });
    },

    requestCode(typeCode = String) {
      this.loadings[`loading${typeCode}`] = true;

      const data = {
        tipo: typeCode,
      };

      this.$store
        .dispatch("codigosSegurancas/gerarCodigo", data)
        .then((response) => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "green",
            mensagem: "Código solicitado com sucesso.",
            duracao: 3000,
          });
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem:
              "Ocorreu um erro ao solicitar o código. Por favor, tente novamente.",
            duracao: 3000,
          });
        })
        .finally(() => {
          this.loadings[`loading${typeCode}`] = false;
        });
    },
  },
};
</script>

<style></style>
