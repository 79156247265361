import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c(VRow,[_c(VCol,{staticClass:"mb-6",attrs:{"cols":"12"}},[_c('h2',[_vm._v("E-mail")]),_c('p',[_vm._v(" Para a realização da troca do e-mail será necessário solicitar o código de segurança que será enviado no e-mail atual. ")]),_c(VBtn,{staticClass:"mb-6",attrs:{"loading":_vm.loadings.loadingE,"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.requestCode('E')}}},[_vm._v(" Solicitar código ")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"codigo",attrs:{"label":"Código de segurança","placeholder":"XXXXX","outlined":"","dense":"","data-vv-as":"código","persistent-hint":"","hint":_vm.errors.first('res-candidato-sensiveis-email.codigo'),"error":_vm.errors.collect('res-candidato-sensiveis-email.codigo').length
                ? true
                : false,"hide-details":_vm.errors.collect('res-candidato-sensiveis-email.codigo').length
                ? false
                : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-sensiveis-email","id":"codigo","name":"codigo"},model:{value:(_vm.trocaEmail.codigo),callback:function ($$v) {_vm.$set(_vm.trocaEmail, "codigo", $$v)},expression:"trocaEmail.codigo"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"8","lg":"9"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],ref:"email",attrs:{"label":"Novo E-mail","placeholder":"xxxxxxx@email.com","outlined":"","dense":"","data-vv-as":"e-mail","persistent-hint":"","hint":_vm.errors.first('res-candidato-sensiveis-email.email'),"error":_vm.errors.collect('res-candidato-sensiveis-email.email').length
                ? true
                : false,"hide-details":_vm.errors.collect('res-candidato-sensiveis-email.email').length
                ? false
                : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-sensiveis-email","id":"email","name":"email"},model:{value:(_vm.trocaEmail.email),callback:function ($$v) {_vm.$set(_vm.trocaEmail, "email", $$v)},expression:"trocaEmail.email"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadings.loadingSaveEmail},on:{"click":function($event){return _vm.saveEmail()}}},[_vm._v(" Salvar ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Senha")]),_c('p',[_vm._v(" A troca de senha segui o mesmo processo que a do e-mail, será necessário solicitar o código de segurança. A seguir o código será enviado no seu e-mail. ")]),_c(VBtn,{staticClass:"mb-6",attrs:{"loading":_vm.loadings.loadingS,"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.requestCode('S')}}},[_vm._v(" Solicitar código ")]),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"codigo",attrs:{"label":"Código de segurança","placeholder":"XXXXX","outlined":"","dense":"","data-vv-as":"código","persistent-hint":"","hint":_vm.errors.first('res-candidato-sensiveis-senha.codigo'),"error":_vm.errors.collect('res-candidato-sensiveis-senha.codigo').length
                ? true
                : false,"hide-details":_vm.errors.collect('res-candidato-sensiveis-senha.codigo').length
                ? false
                : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-sensiveis-senha","id":"codigo","name":"codigo"},model:{value:(_vm.trocaSenha.codigo),callback:function ($$v) {_vm.$set(_vm.trocaSenha, "codigo", $$v)},expression:"trocaSenha.codigo"}})],1),_c(VCol,{staticClass:"d-none d-sm-flex",attrs:{"cols":"12","sm":"12","md":"8","lg":"9"}}),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"senha",attrs:{"label":"Nova Senha","placeholder":"xxxxxxxxxxxxxxx","outlined":"","dense":"","data-vv-as":"senha","persistent-hint":"","hint":_vm.errors.first('res-candidato-sensiveis-senha.senha'),"error":_vm.errors.collect('res-candidato-sensiveis-senha.senha').length
                ? true
                : false,"hide-details":_vm.errors.collect('res-candidato-sensiveis-senha.senha').length
                ? false
                : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-sensiveis-senha","id":"senha","name":"senha","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.trocaSenha.password),callback:function ($$v) {_vm.$set(_vm.trocaSenha, "password", $$v)},expression:"trocaSenha.password"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:senha'),expression:"'required|confirmed:senha'"}],ref:"confirmaSenha",attrs:{"label":"Confirma Senha","placeholder":"xxxxxxxxxxxxxxx","outlined":"","dense":"","data-vv-as":"confirma senha","persistent-hint":"","hint":_vm.errors.first('res-candidato-sensiveis-senha.confirmaSenha'),"error":_vm.errors.collect('res-candidato-sensiveis-senha.confirmaSenha')
                .length
                ? true
                : false,"hide-details":_vm.errors.collect('res-candidato-sensiveis-senha.confirmaSenha')
                .length
                ? false
                : true,"data-vv-validate-on":"change|blur|input","data-vv-scope":"res-candidato-sensiveis-senha","id":"confirmaSenha","name":"confirmaSenha","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.trocaSenha.password_confirmation),callback:function ($$v) {_vm.$set(_vm.trocaSenha, "password_confirmation", $$v)},expression:"trocaSenha.password_confirmation"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadings.loadingSaveSenha},on:{"click":function($event){return _vm.savePassword()}}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }