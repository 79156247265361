import { render, staticRenderFns } from "./InformacoesSensiveis.vue?vue&type=template&id=ad9a5224"
import script from "./InformacoesSensiveis.vue?vue&type=script&lang=js"
export * from "./InformacoesSensiveis.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports